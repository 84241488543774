<template>
  <div v-if="visibleReal" @closed="closed" class="popTire">
    <div class="moto" v-if="carType==='1'">
      <div class="headTitle">
        <h1>{{device.VehicleName}}</h1>
        <span class="hdSet" @click="handlerSet"><img src="../../../assets/images/shezhi.png" alt="">设置</span>
        <span class="close" @click="closed"><i class="el-icon-close"></i></span>
      </div>
      <div class="center">
        <div class="cell" v-for="(item,index) in motoData.list" :class="item.className" :key="index">
          <h2 v-if="item.LowBattery&&item.TyreStatus">{{item.LowBattery?$t('onlineMonitor.tireStatus4'):''}} <span
              v-if="item.TyreStatus"> </span>
            <span v-if="item.TyreStatus===1">{{$t('onlineMonitor.tireStatus1')}}</span>
            <span v-if="item.TyreStatus===2">{{$t('onlineMonitor.tireStatus2')}}</span>
            <span v-if="item.TyreStatus===3">{{$t('onlineMonitor.tireStatus3')}}</span>
          </h2>
          <div class="content">
            <div :class="item.TyrePressStatus&&item.TyrePressStatus!==1?'line red':'line'" @click="handlerChart">
              <span class="ico">
                <i-icon name="icontaiya_xiao-01"></i-icon>
              </span>
              <span class="num">{{item.Bar}}kpa</span>
              <span class="status">
                <template v-if="item.TyrePressStatus===3">
                  <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                </template>
                <template v-if="item.TyrePressStatus===2">
                  <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                </template>
              </span>
              <i v-if="item.TyrePressStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
              <i v-if="item.TyrePressStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
            </div>
            <div :class="item.TemperatureStatus&&item.TemperatureStatus!==1?'line red':'line'" @click="handlerChart">
              <span class="ico">
                <i-icon name="iconwendu_xiao-01"></i-icon>
              </span>
              <span class="num">{{item.Temperature}}℃</span>
              <span class="status">
                <template v-if="item.TemperatureStatus===3">
                  <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                </template>
                <template v-if="item.TemperatureStatus===2">
                  <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                </template>
              </span>
              <i v-if="item.TemperatureStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
              <i v-if="item.TemperatureStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
            </div>
          </div>
        </div>
        <div class="img">
          <div class="in" @click="handlerChart">
            <img src="../../../assets/images/moto3.png" alt="" v-if="motoData.list[0].tip&&motoData.list[1].tip">
            <img src="../../../assets/images/moto2.png" alt="" v-else-if="!motoData.list[0].tip&&motoData.list[1].tip">
            <img src="../../../assets/images/moto2.png" alt="" v-else-if="motoData.list[0].tip&&!motoData.list[1].tip">
            <img src="../../../assets/images/moto.png" alt="" v-else>
          </div>
        </div>
      </div>
    </div>
    <div class="car" v-if="carType==='2'" :class="listLength===4?'minCar':''">
      <div class="headTitle">
        <h1>{{device.VehicleName}}</h1>
        <span class="hdSet" @click="handlerSet"><img src="../../../assets/images/shezhi.png" alt="">设置</span>
        <span class="close" @click="closed"><i class="el-icon-close"></i></span>
      </div>
      <div class="center">
        <div class="groups">
          <div class="cell" v-for="(item,index) in carList" :class="item.className" :key="index" @click="handlerChart">
            <h2>{{item.LowBattery?$t('onlineMonitor.tireStatus4'):''}} <span v-if="item.TyreStatus"> </span>
              <span v-if="item.TyreStatus===1">{{$t('onlineMonitor.tireStatus1')}}</span>
              <span v-if="item.TyreStatus===2">{{$t('onlineMonitor.tireStatus2')}}</span>
              <span v-if="item.TyreStatus===3">{{$t('onlineMonitor.tireStatus3')}}</span>
            </h2>
            <div class="content">
              <div :class="item.TyrePressStatus&&item.TyrePressStatus!==1?'line red':'line'">
                <span class="ico">
                  <i-icon name="icontaiya_xiao-01"></i-icon>
                </span>
                <span class="num">{{item.Bar}}kpa</span>
                <span class="status">
                  <template v-if="item.TyrePressStatus===3">
                    <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                  </template>
                  <template v-if="item.TyrePressStatus===2">
                    <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                  </template>
                </span>
                <i v-if="item.TyrePressStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
                <i v-if="item.TyrePressStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
              </div>
              <div :class="item.TemperatureStatus&&item.TemperatureStatus!==1?'line red':'line'">
                <span class="ico">
                  <i-icon name="iconwendu_xiao-01"></i-icon>
                </span>
                <span class="num">{{item.Temperature}}℃</span>
                <span class="status">
                  <template v-if="item.TemperatureStatus===3">
                    <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                  </template>
                  <template v-if="item.TemperatureStatus===2">
                    <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                  </template>
                </span>
                <i v-if="item.TemperatureStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
                <i v-if="item.TemperatureStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
              </div>
            </div>
          </div>
        </div>
        <div class="img">
          <div class="wheel" v-for="(item,index) in carList" :class="item.className" :key="index" @click="handlerChart">
            <img src="../../../assets/images/wh2.png" alt=""
              v-if="item.LowBattery||item.TyreStatus||(item.TyrePressStatus&&item.TyrePressStatus!==1)||(item.TemperatureStatus&&item.TemperatureStatus!==1)">
            <img src="../../../assets/images/wh.png" alt="" v-else>
          </div>
          <div class="in">
            <img src="../../../assets/images/car.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="van" v-if="carType==='3'" :class="listLength===4?'minVan':''">
      <div class="headTitle">
        <h1>{{device.VehicleName}}</h1>
        <span class="hdSet" @click="handlerSet"><img src="../../../assets/images/shezhi.png" alt="">设置</span>
        <span class="close" @click="closed"><i class="el-icon-close"></i></span>
      </div>
      <div class="center">
        <div class="groups">
          <div class="cell" v-for="(item,index) in carList" :class="item.className" :key="index" @click="handlerChart">
            <h2>{{item.LowBattery?$t('onlineMonitor.tireStatus4'):''}} <span v-if="item.TyreStatus"> </span>
              <span v-if="item.TyreStatus===1">{{$t('onlineMonitor.tireStatus1')}}</span>
              <span v-if="item.TyreStatus===2">{{$t('onlineMonitor.tireStatus2')}}</span>
              <span v-if="item.TyreStatus===3">{{$t('onlineMonitor.tireStatus3')}}</span>
            </h2>
            <div class="content">
              <div :class="item.TyrePressStatus&&item.TyrePressStatus!==1?'line red':'line'">
                <span class="ico">
                  <i-icon name="icontaiya_xiao-01"></i-icon>
                </span>
                <span class="num">{{item.Bar}}kpa</span>
                <span class="status">
                  <template v-if="item.TyrePressStatus===3">
                    <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                  </template>
                  <template v-if="item.TyrePressStatus===2">
                    <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                  </template>
                </span>
                <i v-if="item.TyrePressStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
                <i v-if="item.TyrePressStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
              </div>
              <div :class="item.TemperatureStatus&&item.TemperatureStatus!==1?'line red':'line'">
                <span class="ico">
                  <i-icon name="iconwendu_xiao-01"></i-icon>
                </span>
                <span class="num">{{item.Temperature}}℃</span>
                <span class="status">
                  <template v-if="item.TemperatureStatus===3">
                    <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                  </template>
                  <template v-if="item.TemperatureStatus===2">
                    <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                  </template>
                </span>
                <i v-if="item.TemperatureStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
                <i v-if="item.TemperatureStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
              </div>
            </div>
          </div>
        </div>
        <div class="img">
          <div class="wheel" v-for="(item,index) in carList" :class="item.className" :key="index" @click="handlerChart">
            <img src="../../../assets/images/wh2.png" alt=""
              v-if="item.LowBattery||item.TyreStatus||(item.TyrePressStatus&&item.TyrePressStatus!==1)||(item.TemperatureStatus&&item.TemperatureStatus!==1)">
            <img src="../../../assets/images/wh.png" alt="" v-else>
          </div>
          <div class="in">
            <img src="../../../assets/images/van.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="truck" v-if="carType==='4'">
      <div class="headTitle">
        <h1>{{device.VehicleName}}</h1>
        <span class="hdSet" @click="handlerSet"><img src="../../../assets/images/shezhi.png" alt="">设置</span>
        <span class="close" @click="closed"><i class="el-icon-close"></i></span>
      </div>
      <div class="center">
        <div class="col2">
          <div class="wheel" v-for="(item,index) in truckList.pre" :key="index"
            :class="item.TyreNo===1?'top':'bottom'" @click="handlerChart">
            <div class="box">
              <img src="../../../assets/images/wheel3.png" alt=""
                v-if="item.LowBattery||item.TyreStatus||(item.TyrePressStatus&&item.TyrePressStatus!==1)||(item.TemperatureStatus&&item.TemperatureStatus!==1)">
              <img src="../../../assets/images/wheel.png" alt="" v-else>
              <!-- <div class="text">
                <h4>{{item.LowBattery?$t('onlineMonitor.tireStatus4'):''}} <span v-if="item.TyreStatus"> </span>
                  <span v-if="item.TyreStatus===1">{{$t('onlineMonitor.tireStatus1')}}</span>
                  <span v-if="item.TyreStatus===2">{{$t('onlineMonitor.tireStatus2')}}</span>
                  <span v-if="item.TyreStatus===3">{{$t('onlineMonitor.tireStatus3')}}</span>
                </h4>
              </div> -->
            </div>
          </div>
          <div class="gap2"><img src="../../../assets/images/gap1.png" alt=""></div>
        </div>
        <div class="context2">
          <div class="block" v-for="(item,index) in truckList.pre" :key="index"
            :class="item.TyreNo===1?'top':'bottom'" @click="handlerChart">
            <div :class="item.TyrePressStatus&&item.TyrePressStatus!==1?'line red':'line'">
              <span class="ico">
                <i-icon name="icontaiya_xiao-01"></i-icon>
              </span>
              <span class="num">{{item.Bar}}kpa</span>
              <span class="status">
                <template v-if="item.TyrePressStatus===3">
                  <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                </template>
                <template v-if="item.TyrePressStatus===2">
                  <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                </template>
              </span>
              <i v-if="item.TyrePressStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
              <i v-if="item.TyrePressStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
            </div>
            <div :class="item.TemperatureStatus&&item.TemperatureStatus!==1?'line red':'line'">
              <span class="ico">
                <i-icon name="iconwendu_xiao-01"></i-icon>
              </span><span class="num">{{item.Temperature}}℃</span>
              <span class="status">
                <template v-if="item.TemperatureStatus===3">
                  <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                </template>
                <template v-if="item.TemperatureStatus===2">
                  <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                </template>
              </span>
              <i v-if="item.TemperatureStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
              <i v-if="item.TemperatureStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
            </div>
            <div class="line" v-if="item.LowBattery">
              <span class="status">{{$t('onlineMonitor.tireStatus4')}}</span>
            </div>
            <div class="line" v-if="item.TyreStatus">
              <span v-if="item.TyreStatus===1" class="status fk">{{$t('onlineMonitor.tireStatus1')}}</span>
              <span v-if="item.TyreStatus===2" class="status fk">{{$t('onlineMonitor.tireStatus2')}}</span>
              <span v-if="item.TyreStatus===3" class="status fk">{{$t('onlineMonitor.tireStatus3')}}</span>
            </div>
          </div>
        </div>
        <div v-for="(items,index) in truckList.next" :key="index" class="row">
          <div class="col">
            <div class="wheel" v-for="(item,index) in items" :key="index" :class="item.className" @click="handlerChart">
              <div class="box">
                <img src="../../../assets/images/wheel3.png" alt=""
                  v-if="item.LowBattery||item.TyreStatus||(item.TyrePressStatus&&item.TyrePressStatus!==1)||(item.TemperatureStatus&&item.TemperatureStatus!==1)">
                <img src="../../../assets/images/wheel.png" alt="" v-else>
                <!-- <div class="text">
                  <h4>{{item.LowBattery?$t('onlineMonitor.tireStatus4'):''}} <span v-if="item.TyreStatus"> </span>
                    <span v-if="item.TyreStatus===1">{{$t('onlineMonitor.tireStatus1')}}</span>
                    <span v-if="item.TyreStatus===2">{{$t('onlineMonitor.tireStatus2')}}</span>
                    <span v-if="item.TyreStatus===3">{{$t('onlineMonitor.tireStatus3')}}</span>
                  </h4>
                </div> -->
              </div>
            </div>
            <div class="top">
              <div class="gap"><img src="../../../assets/images/gap3.png" alt=""></div>
            </div>
            <div class="mid"><img src="../../../assets/images/gap2.png" alt=""></div>
            <div class="bottom">
              <div class="gap"><img src="../../../assets/images/gap3.png" alt=""></div>
            </div>
          </div>
          <div class="context">
            <div class="div" v-for="(item,index) in items" :key="index" :class="item.className" @click="handlerChart">
              <div class="block">
                <div :class="item.TyrePressStatus&&item.TyrePressStatus!==1?'line red':'line'">
                  <span class="ico">
                    <i-icon name="icontaiya_xiao-01"></i-icon>
                  </span>
                  <span class="num">{{item.Bar}}kpa</span>
                  <span class="status">
                    <template v-if="item.TyrePressStatus===3">
                      <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                    </template>
                    <template v-if="item.TyrePressStatus===2">
                      <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                    </template>
                  </span>
                  <i v-if="item.TyrePressStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
                  <i v-if="item.TyrePressStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
                </div>
                <div :class="item.TemperatureStatus&&item.TemperatureStatus!==1?'line red':'line'">
                  <span class="ico">
                    <i-icon name="iconwendu_xiao-01"></i-icon>
                  </span><span class="num">{{item.Temperature}}℃</span>
                  <span class="status">
                    <template v-if="item.TemperatureStatus===3">
                      <i-icon name="iconshiliangzhinengduixiang-02"></i-icon>
                    </template>
                    <template v-if="item.TemperatureStatus===2">
                      <i-icon name="iconshiliangzhinengduixiang-01"></i-icon>
                    </template>
                  </span>
                  <i v-if="item.TemperatureStatus===3">{{$t('onlineMonitor.tireLow')}}</i>
                  <i v-if="item.TemperatureStatus===2">{{$t('onlineMonitor.tireHight')}}</i>
                </div>
                <div class="line" v-if="item.LowBattery">
                  <span class="status">{{$t('onlineMonitor.tireStatus4')}}</span>
                </div>
                <div class="line" v-if="item.TyreStatus">
                  <span v-if="item.TyreStatus===1" class="status fk">{{$t('onlineMonitor.tireStatus1')}}</span>
                  <span v-if="item.TyreStatus===2" class="status fk">{{$t('onlineMonitor.tireStatus2')}}</span>
                  <span v-if="item.TyreStatus===3" class="status fk">{{$t('onlineMonitor.tireStatus3')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as API from '@/api/common'
  import {
    sortPostion
  } from '@/common/utils'
  export default {
    props: {
      upgrade: {
        type: Number,
        default: 15
      },
    },
    data() {
      return {
        id: 0,
        visibleReal: false,
        device: '',
        carType: '2',
        listLength: 0,
        motoData: {
          status: 0,
          list: []
        },
        // vanList: [],
        carList: [],
        truckList: {
          pre: [],
          next: []
        }
      }
    },
    watch: {
      upgrade(next, pre) {
        if (next && next != pre) {
          if (this.visibleReal && next === 1) {
            this.fetchData(this.device.ObjectID, this.device)
          }
        }
      }
      // currentCommand() {
      //   this.checkedObjectKeys.splice(0)
      //   // this.checkedObjectList.splice(0)
      //   this.treeVisible = false
      //   setTimeout(() => {
      //     this.treeVisible = true
      //   })
      // },
    },
    methods: {
      open(id, device) {
        this.$nextTick(() => {
          this.id = id
          this.device = device
          this.fetchData(id, device)
        })
      },
      closed() {
        this.visibleReal = false
      },
      handlerChart() {
        this.$emit('tire', this.device)
      },
      handlerSet() {
        const obj = {
          id: this.id,
          edit: true
        }
        this.visibleReal = false
        this.$emit('setire', obj)
      },
      fetchData(id, device) {
        API.GetVehicleTyreList(id).then((ret) => {
          let list = ret.data
          if (!list.length) {
            this.$message('没有胎压相关信息')
            this.visibleReal = false
            const obj = {
              id: id,
              edit: false
            }
            this.$emit('setire', obj)
            return
          }
          this.listLength = list.length
          this.visibleReal = true
          if (list.length === 4 || list.length === 5) {
            let pos = ['lt', 'lb', 'rt', 'rb', 'end']
            let carList = sortPostion(list)
            carList.forEach((item, index) => {
              item.className = pos[index]
            })
            this.carList = carList
            if (device.VehicleImageUrl.indexOf('car') != -1) {
              this.carType = '2'
            } else {
              this.carType = '3'
            }
          } else if (list.length > 5) {
            this.carType = '4'
            list = sortPostion(list)
            // console.log(list)
            this.truckList.pre = list.slice(0, 2)
            let pre = list.slice(0, 2)
            // if(parseInt(pre[0].TyreNo)>parseInt(pre[1].TyreNo)){
            //   pre[0].className = 'top'
            //   pre[1].className = 'bottom'
            // }else{
            //   pre[0].className = 'bottom'
            //   pre[1].className = 'top'
            // }

            let next = list.slice(2)
            let index = 0;
            let temp = [];
            while (index < next.length) {
              temp.push(next.slice(index, index += 4))
            }
            temp.forEach((item) => {
              item = sortPostion(item)
              item.forEach((k, i) => {
                k.className = 'c' + i
              })
            })
            this.truckList.next = temp
            this.truckList.pre = pre
          } else if (list.length === 2) {
            this.carType = '1'
            // let status = 0
            list.forEach(element => {
              let className = 'right'
              switch (element.TyreNo) {
                case 2:
                  className = 'right'
                  break;
                default:
                  className = 'left'
              }
              if (element.LowBattery || element.TyreStatus || (element.TyrePressStatus && element
                  .TyrePressStatus !== 1) || (element.TemperatureStatus && element.TemperatureStatus !== 1)) {
                element.tip = 1
              } else {
                element.tip = 0
              }
              element.className = className
            })

            this.motoData.list = list
            // this.motoData.status = status
          }
          // this.list = list
        })
      }
    },
    mounted() {

    }
  }

</script>
<style lang="scss" scoped>
  .popTire {
    position: fixed;
    right: 20px;
    bottom: 60px;
    z-index: 3;
  }

  .headTitle {
    height: 40px;
    padding: 0 15px;

    h1 {
      text-indent: 15px;
      line-height: 40px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #111111;
      position: relative;
      z-index: 1;
    }

    h1::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #1890ff;
      position: absolute;
      top: 16px;
      left: 0px;
    }

    .close {
      padding: 8px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 3;
      cursor: pointer;
    }

    .close:hover {
      color: #111111;
    }

    .hdSet {
      padding: 3px 8px;
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 20px;
      right: 45px;
      z-index: 3;
      cursor: pointer;
      color: #1890FF;

      img {
        float: left;
      }
    }

  }

  .cell {
    position: absolute;
    min-width: 130px;
    // height: 68px;
    background-color: #ffffff;
    border-radius: 6px;
    border: solid 1px #dbdbdb;

    h2 {
      padding: 0 8px;
      // height: 22px;
      line-height: 22px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #444444;
      border-bottom: 1px solid #dbdbdb;
    }

    .content {
      padding: 8px;

      .line {
        cursor: pointer;
        height: 20px;
        font-size: 12px;
        color: #111111;

        .ico {
          font-size: 18px;
          position: relative;
          top: 0px;
        }

        .num {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;

        }

        .status {
          font-size: 12px;
          position: relative;
          top: -1px;
        }

        i {
          font-size: 12px;
          font-style: normal;
        }
      }

      .red {
        color: #d91629;
      }
    }
  }

  .moto {
    width: 400px;
    height: 338px;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 1px #a1acb7;

    .center {
      padding-top: 100px;
      position: relative;
      z-index: 1;

      .cell::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #dbdbdb;
        position: absolute;
        z-index: 3;
      }

      .left {
        left: 30px;
        bottom: 200px;
      }

      .left::after {
        content: '';
        display: block;
        width: 41px;
        height: 140px;
        border: solid 1px #dbdbdb;
        border-top: none;
        border-right: none;
        position: absolute;
        left: 24px;
        top: 56px;
        z-index: -1;
      }

      .left:before {
        left: 64px;
        top: 193px;
      }

      .right {
        right: 30px;
        bottom: 200px;
      }

      .right::after {
        content: '';
        display: block;
        width: 41px;
        height: 140px;
        border: solid 1px #dbdbdb;
        border-top: none;
        border-left: none;
        position: absolute;
        right: 24px;
        top: 56px;
        z-index: -1;
      }

      .right:before {
        right: 64px;
        top: 193px;
      }

      .img {
        width: 252px;
        height: 173px;
        margin: 0 auto;
        // position: relative;
        // z-index: 1;

        .in {
          margin: 0 auto;
          // padding-top: 10px;
          width: 230px;

          img {
            width: 100%;
          }
        }

      }
    }
  }

  .car {
    width: 530px;
    height: 392px;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 1px #a1acb7;

    .center {
      padding: 100px 0;
      margin-left: 35px;
      position: relative;
      z-index: 1;

      .groups {
        width: 100%;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        .cell {
          cursor: pointer;
        }

        .cell::after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
        }

        .cell::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #dbdbdb;
          position: absolute;
          z-index: 2;
        }

        .lt {
          left: 30px;
          top: 20px;
        }

        .lt::after {
          width: 1px;
          height: 42px;
          border-right: solid 1px #dbdbdb;
          left: 54px;
          top: 56px;
        }

        .lt::before {
          left: 53px;
          top: 93px;
        }

        .rt {
          left: 230px;
          top: 20px;
        }

        .rt::after {
          width: 17px;
          height: 64px;
          border: solid 1px #dbdbdb;
          border-bottom: none;
          border-right: none;
          left: -18px;
          top: 30px;
        }

        .rt::before {
          left: -20px;
          top: 93px;
        }

        .lb {
          left: 30px;
          bottom: -322px;
        }

        .lb::after {
          width: 1px;
          height: 42px;
          border-right: solid 1px #dbdbdb;
          left: 54px;
          bottom: 56px;
        }

        .lb::before {
          left: 53px;
          bottom: 93px;
        }

        .rb {
          left: 230px;
          bottom: -322px;
        }

        .rb::after {
          width: 17px;
          height: 64px;
          border: solid 1px #dbdbdb;
          border-top: none;
          border-right: none;
          left: -18px;
          bottom: 30px;
        }

        .rb::before {
          left: -20px;
          bottom: 93px;
        }

        .end {
          left: 315px;
          top: 120px;
        }

        .end::after {
          width: 30px;
          height: 1px;
          border-top: solid 1px #dbdbdb;
          left: -30px;
          bottom: 26px;
        }

        .end::before {
          left: -30px;
          bottom: 25px;
        }

      }

      .img {
        width: 312px;
        height: 139px;
        margin-left: 10px;
        position: relative;
        z-index: 1;

        .wheel {
          width: 52px;
          height: 18px;
          position: absolute;
          cursor: pointer;
        }

        .lt {
          top: 8px;
          left: 40px;
        }

        .rt {
          top: 8px;
          right: 80px;
        }

        .lb {
          bottom: 8px;
          left: 40px;
        }

        .rb {
          bottom: 8px;
          right: 80px;
        }

        .end {
          bottom: 58px;
          right: 10px;
          transform: rotate(90deg);
        }

        .in {
          width: 312px;
          height: 139px;
          margin: 0 auto;

          img {
            width: 290px;
          }
        }
      }
    }
  }

  .van {
    width: 540px;
    height: 420px;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 1px #a1acb7;

    .center {
      padding: 90px 0;
      position: relative;
      z-index: 1;

      .groups {
        width: 100%;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        .cell {
          cursor: pointer;
        }

        .cell::after {
          content: '';
          width: 16px;
          height: 74px;
          border: solid 1px #dbdbdb;
          display: block;
          position: absolute;
          z-index: -1;
        }

        .cell::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #dbdbdb;
          position: absolute;
          z-index: 2;
        }

        .lt {
          left: 10px;
          top: 20px;
        }

        .lt::after {
          border-bottom: none;
          border-left: none;
          left: 128px;
          top: 30px;
        }

        .lt::before {
          left: 142px;
          top: 100px;
        }

        .rt {
          left: 307px;
          top: 20px;
        }

        .rt::after {
          height: 54px;
          border-bottom: none;
          border-right: none;
          left: -17px;
          top: 30px;
        }

        .rt::before {
          left: -19px;
          top: 81px;
        }

        .lb {
          left: 10px;
          top: 252px;
        }

        .lb::after {
          border-top: none;
          border-left: none;
          left: 128px;
          bottom: 30px;
        }

        .lb::before {
          left: 142px;
          bottom: 100px;
        }

        .rb {
          left: 307px;
          top: 250px;
        }

        .rb::after {
          height: 54px;
          border-top: none;
          border-right: none;
          left: -17px;
          bottom: 30px;
        }

        .rb::before {
          left: -19px;
          bottom: 81px;
        }

        .end {
          left: 390px;
          top: 146px;
        }

        .end::after {
          width: 40px;
          height: 0;
          border-bottom: 0;
          left: -42px;
          top: 30px;
        }

        .end::before {
          left: -42px;
          top: 28px;
        }

      }

      .img {
        width: 318px;
        height: 173px;
        margin-left: 70px;
        position: relative;
        z-index: 1;

        .wheel {
          width: 52px;
          height: 18px;
          cursor: pointer;
          position: absolute;
        }

        .lt {
          top: 25px;
          left: 60px;
        }

        .rt {
          top: 8px;
          right: 72px;
        }

        .lb {
          bottom: 25px;
          left: 60px;
        }

        .rb {
          bottom: 8px;
          right: 72px;
        }

        .end {
          bottom: 75px;
          right: 10px;
          transform: rotate(90deg);
        }
      }
    }
  }

  .truck {
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 1px #a1acb7;

    .center {
      min-height: 300px;
      padding: 0 80px 74px 80px;

      .line {
        cursor: pointer;
        // height: 26px;
        font-size: 13px;
        padding-top: 2px;
        color: #111111;

        .ico {
          font-size: 16px;
          position: relative;
          top: 0px;
        }

        .num {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;

        }

        .status {
          font-size: 12px;
          position: relative;
          top: -1px;
        }

        i {
          font-size: 12px;
          font-style: normal;
        }
      }

      .red {
        color: #d91629;
      }

      .wheel {
        // display: none;
        width: 40px;
        height: 93px;
        cursor: pointer;
        position: absolute;
        z-index: 1;

        .box {
          width: 40px;
          height: 93px;

          img {
            width: 100%;
          }

          .text {
            width: 100%;
            font-size: 12px;
            position: absolute;
            top: 30px;
            text-align: center;
            color: #fff;

            h4 {
              padding-top: 3px;
              line-height: 14px;
            }
          }
        }

      }

      .fk {
        left: 20px;
      }

      .col2 {
        display: flex;
        margin-left: 82px;
        margin-top: 20px;
        min-height: 93px;
        position: relative;
        // width: 236px;

        .gap2 {
          margin-top: 34px;
          padding-left: 50px;

          img {
            width: 156px;
          }
        }

        .top {
          left: 216px;
        }

        .bottom {
          left: 0px;

          .fk {
            left: 0;
          }
        }
      }

      .col {
        display: flex;
        margin-top: 20px;
        margin-right: 60px;
        // width: 236px;
        height: 93px;
        position: relative;
        z-index: 1;


        .top {
          width: 65px;

          .gap {
            margin-top: 38px;
            margin-left: 55px;

            img {
              width: 20px;
            }
          }
        }

        .bottom {
          width: 65px;

          .gap {
            margin-top: 38px;
            margin-left: 55px;

            img {
              width: 20px;
            }
          }
        }


        .mid {
          text-align: center;
          padding-top: 36px;
          margin-left: 60px;

          img {
            width: 176px;
          }
        }

        .c3 {
          left: 10px;
        }

        .c2 {
          left: 80px;
        }

        .c1 {
          left: 310px;
        }

        .c0 {
          left: 380px;
        }
      }

      .context {
        display: flex;

        .div {
          width: 60px;
          margin-right: 10px;
          position: relative;
          z-index: 1;
        }

        .block {
          position: absolute;
          min-width: 140px;
          // left:0;
          // top:0;
          z-index: 0;
        }

        .c0 {
          .block {
            right: 0;
            text-align: right;
          }

          .fk {
            left: 0px;
          }
        }

        .c1 {
          .block {
            left: 0;
            text-align: left;
          }
        }

        .c2 {
          margin-left: 158px;

          .block {
            right: 0;
            text-align: right;
          }

          .fk {
            left: 0px;
          }
        }

        .c3 {
          .block {
            left: 0;
            text-align: left;
          }

        }

        // .block {
        //   width: 100px;
        //   text-align: center;
        //   position: absolute;
        //   left: 0px;
        //   margin-left: -36px;
        //   top: 100px;
        //   z-index: 1;
        // }

      }

      .context2 {
        display: flex;

        .top {
          width: 115px;
          text-align: right;
        }

        .bottom {
          padding-left: 180px;
          text-align: left;
        }
      }

    }
  }

  // .truck {
  //   background-color: #ffffff;
  //   border-radius: 8px;
  //   border: solid 1px #a1acb7;

  //   .center {
  //     min-width: 500px;
  //     padding: 20px 0;
  //     //   height: 315px;
  //     display: flex;

  //     .wheel {
  //       width: 71px;
  //       height: 30px;
  //       position: absolute;
  //       z-index: 1;

  //       .box {
  //         width: 71px;
  //         height: 30px;
  //         position: relative;
  //         z-index: 1;

  //         .text {
  //           width: 100%;
  //           font-size: 12px;
  //           position: absolute;
  //           top: 0;
  //           text-align: center;
  //           color: #fff;

  //           h4 {
  //             padding-top: 3px;
  //             line-height: 14px;
  //           }
  //         }


  //       }

  //       .block {
  //         width: 100px;
  //         text-align: left;
  //         position: absolute;
  //         right: -105px;
  //         top: -6px;
  //         z-index: 1;
  //       }

  //       .line {
  //         cursor: pointer;
  //         height: 20px;
  //         font-size: 12px;
  //         color: #111111;

  //         .ico {
  //           font-size: 16px;
  //           position: relative;
  //           top: 0px;
  //         }

  //         .num {
  //           font-size: 12px;
  //           font-weight: bold;
  //           font-stretch: normal;
  //           letter-spacing: 0px;

  //         }

  //         .status {
  //           font-size: 12px;
  //           position: relative;
  //           top: -1px;
  //         }

  //         i {
  //           font-size: 12px;
  //           font-style: normal;
  //         }
  //       }

  //       .red {
  //         color: #d91629;
  //       }
  //     }

  //     .col2 {
  //       padding-top: 35px;
  //       margin-left: 40px;
  //       position: relative;
  //       width: 71px;
  //       height: 236px;
  //       z-index: 1;

  //       .gap2 {
  //         margin-top: 30px;
  //       }

  //       .top {
  //         top: 30px;
  //       }

  //       .bottom {
  //         bottom: 15px;
  //       }
  //     }

  //     .col {
  //       margin-left: 120px;
  //       width: 71px;
  //       height: 236px;
  //       position: relative;
  //       z-index: 1;

  //       .top {
  //         height: 54px;

  //         .gap {
  //           margin-top: 30px;
  //         }
  //       }

  //       .bottom {
  //         height: 54px;

  //         .gap {
  //           margin-top: 30px;
  //         }
  //       }

  //       .c1 {
  //         top: 54px;
  //       }

  //       .c2 {
  //         bottom: 54px;
  //       }

  //       .c3 {
  //         bottom: -3px;
  //       }
  //     }

  //     .gap {
  //       width: 100%;
  //       height: 24px;
  //       text-align: center;

  //       img {
  //         margin-top: 2px;
  //       }
  //     }

  //     .gap2 {
  //       height: 116px;
  //       text-align: center;

  //       img {
  //         margin-top: 4px;
  //       }
  //     }

  //     .mid {
  //       height: 68px;
  //       text-align: center;

  //       img {
  //         margin-top: 4px;
  //       }
  //     }
  //   }
  // }
  .minCar {
    width: 430px;

    .center {
      margin-left: 15px;
    }
  }

  .minVan {
    width: 454px;
  }

</style>
